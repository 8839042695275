export const scheduleData = [
    {
        id: 1,
        time: '06:00',
        title: 'Warm-up session',
        desc: ''
    },
    {
        id: 2,
        time: '06:20',
        title: 'Flag off Event',
        desc: ''
    },
    {
        id: 3,
        time: '08:15',
        title: 'Culturals',
        desc: ''
    },
    {
        id: 4,
        time: '09:00',
        title: 'Yellow Club session on Mental Health Awareness',
        desc: ''
    },
    {
        id: 5,
        time: '09:15',
        title: 'prize distribution and Closing ceremony',
        desc: ''
    },
    {
        id: 6,
        time: '10:00',
        title: 'Entertainment session',
        desc: ''
    },
]
